/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import fetch from 'fetch';

const BASE_DISTRIBUTION_URL =
  'https://update-distribution.phorest.com/electron/downloads';

export default class ElectronDownloader extends Component {
  @tracked latestRelease;

  constructor() {
    super(...arguments);

    this.fetchManifestTask.perform();
  }

  @task
  *fetchManifestTask() {
    const request = yield fetch(this.releaseManifestURL, { cache: 'no-cache' });

    if (this.args.platform === 'darwin') {
      this.latestRelease = yield this.parseMacManifest(request);
    } else {
      this.latestRelease = yield this.parseWindowsManifest(request);
    }

    if (typeof this.args.onManifest === 'function') {
      this.args.onManifest(this.latestRelease);
    }
  }

  async parseWindowsManifest(request) {
    const response = await request.text();
    const latest = response.split('\n').pop();
    const filename = latest.split(' ')[1];

    return {
      version: filename.match(/-(\d+\.\d+\.\d+)-/)[1],
      url: this.convertWindowsURL(filename),
    };
  }

  async parseMacManifest(request) {
    const response = await request.json();

    const release = response.releases.find(
      (r) => r.version === response.currentRelease
    );
    const { version, url } = release?.updateTo ?? {};

    return { version, url: this.convertMacURL(url) };
  }

  convertMacURL(url) {
    if (url) {
      return url
        .replace(`darwin-${this.args.arch}-`, '')
        .replace(/\.zip$/, `-${this.args.arch}.dmg`);
    }

    return '';
  }

  convertWindowsURL(filename) {
    const newFilename = filename
      .replace('PhorestDesktop', 'Phorest Desktop')
      .replace('-full', ' Setup')
      .replace(/.nupkg$/, '.exe');

    return `${BASE_DISTRIBUTION_URL}/${this.args.platform}/${this.args.arch}/${newFilename}`;
  }

  get downloadURL() {
    return this.latestRelease?.url ?? '';
  }

  get releaseManifestURL() {
    const filename =
      this.args.platform === 'darwin' ? 'RELEASES.json' : 'RELEASES';
    return `${BASE_DISTRIBUTION_URL}/${this.args.platform}/${this.args.arch}/${filename}`;
  }

  @action
  handleClick() {
    if (typeof this.args.onDownload === 'function') {
      this.args.onDownload();
    }
  }
}
