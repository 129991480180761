import Service, { service } from '@ember/service';

export default class PlatformService extends Service {
  @service('browser/window') window;

  get isWindows() {
    return this.window.navigator.userAgent.includes('Windows');
  }

  get isMacOS() {
    return this.window.navigator.userAgent.includes('Macintosh');
  }
}
