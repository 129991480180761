import { underscore } from '@ember/string';
import { variation } from 'ember-launch-darkly';

export function moustachify(key) {
  return '{{' + underscore(key).toUpperCase() + '}}';
}

export async function importTinymce() {
  const { default: tinymce } = await import('tinymce');

  await Promise.all([
    import('tinymce/themes/silver/theme'),
    import('tinymce/models/dom/model'),
    import('tinymce/icons/default/icons'),
    import('tinymce/skins/ui/tinymce-5/skin.css'),
    import('tinymce/plugins/lists'),
    import('tinymce/plugins/emoticons'),
    import('tinymce/plugins/emoticons/js/emojis'),
  ]);

  return tinymce;
}

export async function importSanitizeHTML() {
  return (await import('sanitize-html')).default;
}

export function addAttribution(editor, attributionSVG) {
  editor.ui.registry.addIcon('tinymce', attributionSVG);

  editor.ui.registry.addButton('attribution-link', {
    icon: 'tinymce',
    onAction: () => {
      window.open('https://www.tiny.cloud', '_blank');
    },
  });
}

export function getMacroBadge(macro, intl) {
  const macroName = `notification-templates.macros.${macro}`;
  // XXX can't use tailwindcss classes here because of tinymce internals
  return `<span style="background-color: #CCFBF1; color: #4b5563; padding: 2px 4px; border-radius: 4px; font-weight: 500;" contenteditable="false" data-macro-id="${macro}">${intl.t(
    macroName
  )}</span>`;
}

export function addPersonaliseButton(editor, macros, intl) {
  editor.ui.registry.addMenuButton('personalise', {
    text: intl.t('ui.editor.personalise'),
    fetch: function (callback) {
      const values = macros.map(({ name, enabled, onAction }) => ({
        type: 'menuitem',
        text: intl.t(`notification-templates.macros.${name}`),
        enabled,
        onAction: () => {
          if (onAction) {
            onAction();
          } else {
            const content = getMacroBadge(name, intl);
            editor.insertContent(
              variation('release-marketing-happy-salon-macro-whitespace')
                ? content + '&nbsp;'
                : content
            );
            editor.dispatch('input');
          }
        },
        onSetup: function (api) {
          _onSelectMenuSetup(editor, api);
        },
      }));
      callback(values);
    },
  });
}

export function addLinkButton(editor, link, intl, onAction) {
  editor.ui.registry.addToggleButton('add-link', {
    icon: 'link',
    text: intl.t('ui.editor.link'),
    tooltip: 'Link',
    onAction: () => {
      if (onAction) {
        onAction(link);
      } else {
        const macroTemplate = getMacroBadge(link, intl);
        editor.insertContent(
          variation('release-marketing-happy-salon-macro-whitespace')
            ? macroTemplate + '&nbsp;'
            : macroTemplate
        );
        editor.dispatch('input');
      }
    },
  });
}

export function addLinksButton(editor, links, intl, onAction) {
  editor.ui.registry.addMenuButton('add-links', {
    text: intl.t('ui.editor.links'),
    fetch: function (callback) {
      const values = links.map(({ link, enabled }) => ({
        type: 'menuitem',
        text: intl.t(`notification-templates.macros.${link}`),
        enabled,
        onAction: () => {
          if (onAction) {
            onAction(link);
          } else {
            const macroTemplate = getMacroBadge(link, intl);
            editor.insertContent(
              variation('release-marketing-happy-salon-macro-whitespace')
                ? macroTemplate + '&nbsp;'
                : macroTemplate
            );
            editor.dispatch('input');
          }
        },
        onSetup: function (api) {
          _onSelectMenuSetup(editor, api);
        },
      }));
      callback(values);
    },
  });
}

export function addCustomLinkButton(editor, intl, onAction) {
  editor.ui.registry.addToggleButton('add-custom-link', {
    icon: 'link',
    text: intl.t('marketing.custom-link'),
    tooltip: 'Link',
    onAction: () => {
      if (onAction) {
        onAction();
      }
    },
  });
}

export function replaceMacroBadgeInContent(content, macro) {
  const container = document.createElement('div');
  container.innerHTML = content;
  const elems = container.querySelectorAll(`[data-macro-id="${macro}"]`);

  elems.forEach((elem) => {
    elem.parentNode.replaceChild(
      document.createTextNode(moustachify(macro)),
      elem
    );
  });

  return container.innerHTML;
}

export function replaceCustomLinkTextInContent(content, link) {
  const container = document.createElement('a');
  container.textContent = content;
  container.href = link;
  container.style = `color: #0F766E; text-decoration: none;`;
  const elems = container.querySelectorAll(`[data-macro-id="${link}"]`);

  elems.forEach((elem) => {
    elem.parentNode.replaceChild(
      document.createTextNode(moustachify(link)),
      elem
    );
  });

  return container.innerHTML;
}

export function wireEditorEventsToInputEvent(editor) {
  editor.on('undo', () => editor.dispatch('input'));
  editor.on('redo', () => editor.dispatch('input'));
  editor.on('paste', () => editor.dispatch('input'));
  editor.on('change', () => editor.dispatch('input'));
}

/**
 * Macro is not editable so the text inside can't be changed. Unfortunately, by using contenteditable="false" on macro we also
 * prevent TinyMCE from styling that element. That's done by design by TinyMCE team, although people question if that's the
 * right approach: ["Cannot apply basic style to non editable elements"](https://github.com/tinymce/tinymce/issues/3355).
 *
 * We fire this function before command execution to remove "contenteditable" attribute from all macros.
 * That allows the command to apply the style to the macro.
 *
 * When command is executed we add "contenteditable" attribute back to macros (see: addContentEditableAttributeToMacros() method)
 *
 * @param {object} editor
 * @param {string} command
 * @private
 */
function removeContentEditableAttributeFromMacros(editor, command) {
  // The commands we want to permit formatting contenteditable="false" macros
  const textFormatCommands = [
    'FontName',
    'FontSize',
    'mceRemoveTextcolor',
    'mceApplyTextcolor',
    'mceToggleFormat',
  ];

  if (textFormatCommands.includes(command)) {
    const editorBody = editor.getBody();
    const macros = editorBody.querySelectorAll('[data-macro-id]');

    for (const macro of macros) {
      macro.removeAttribute('contenteditable');
    }
  }
}

function addContentEditableAttributeToMacros(editor) {
  const editorBody = editor.getBody();
  const macros = editorBody.querySelectorAll('[data-macro-id]');

  for (const macro of macros) {
    macro.setAttribute('contenteditable', false);
  }
}

export function handleContentEditableAttributeInMacros(editor) {
  editor.on('BeforeExecCommand', (e) => {
    removeContentEditableAttributeFromMacros(editor, e.command);
  });
  editor.on('ExecCommand', () => {
    addContentEditableAttributeToMacros(editor);
  });
  editor.on('BeforeSetContent', function (e) {
    //Put a cachebuster on all images to prevent welcome email from being cached
    if (/<img src="([^"]+)"/g.test(e.content)) {
      e.content = e.content.replace(
        /<img src="([^"]+)"/g,
        '<img src="$1?' + new Date().getTime() + '"'
      );
    }
  });
}

function _onSelectMenuSetup(editor, api) {
  const callback = function (e) {
    let macro = e.element.getAttribute('data-macro-id');

    if (!macro && e.element?.firstChild?.getAttribute) {
      macro = e.element.firstChild.getAttribute('data-macro-id');
    }

    api.setEnabled(!!macro);
  };
  editor.on('NodeChange', callback);

  return function () {
    editor.off('NodeChange', callback);
  };
}
