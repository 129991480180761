import { CalendarEvent } from 'my-phorest/utils/calendar-events';
import { A } from '@ember/array';
import { assert } from '@ember/debug';
import {
  datesBetween,
  fromJSDate as dateFromJSDate,
} from 'my-phorest/utils/local-date-helpers';
import {
  addTime,
  fromJSDate as timeFromJSDate,
} from 'my-phorest/utils/local-time-helpers';
import { variation } from 'ember-launch-darkly';

export const DRAG_AND_DROP_GRANULARITY_MIN = '5';

export const ACCEPTED_ISSUES = [
  // These are the only accepted issues that we handle (show popup with confirmation)
  // 'STAFF_UNQUALIFIED',
  // 'MACHINE_UNSUITABLE',
  // 'ROOM_UNSUITABLE',
  // 'MIN_DAYS_BETWEEN_SESSIONS_NOT_EXCEEDED',
  // 'MAX_DAYS_BETWEEN_SESSIONS_EXCEEDED',
  // 'EXCEEDED_ROOM_CAPACITY',
  // 'MACHINE_DOUBLE_BOOKED',
  // 'SPECIAL_OFFER_NOT_ALLOWED_TIME',
  // 'SPECIAL_OFFER_BEFORE_START_DATE',
  // 'SPECIAL_OFFER_AFTER_END_DATE',
  // 'SPECIAL_OFFER_MAX_CONCURRENT_EXCEEDED',
  // 'SPECIAL_OFFER_MAX_PER_DAY_EXCEEDED',
  // 'SPECIAL_OFFER_STAFF_EXCLUDED',
  // 'SPECIAL_OFFER_STAFF_NOT_INCLUDED',
  // 'STAFF_REQUESTED',
  // 'STAFF_DOUBLE_BOOKED',
  // 'STAFF_NOT_WORKING',

  // We accept/ignore the following issues on upsert operations for now:
  'SERVICE_ALREADY_BOOKED_THIS_DAY',
  'INTERNET_BOOKING_ALREADY_BOOKED',
  'CLIENT_ALREADY_BOOKED_THIS_TIME',
];

export const MACHINE_UNSUITABLE_ISSUE = 'MACHINE_UNSUITABLE';
export const ROOM_UNSUITABLE_ISSUE = 'ROOM_UNSUITABLE';
export const STAFF_UNQUALIFIED_ISSUE = 'STAFF_UNQUALIFIED';
export const DEPOSIT_SELF_EMPLOYED_STAFF_ISSUE = 'DEPOSIT_SELF_EMPLOYED_STAFF';
export const MIN_DAYS_BETWEEN_SESSIONS_NOT_EXCEEDED_ISSUE =
  'MIN_DAYS_BETWEEN_SESSIONS_NOT_EXCEEDED';
export const MAX_DAYS_BETWEEN_SESSIONS_EXCEEDED_ISSUE =
  'MAX_DAYS_BETWEEN_SESSIONS_EXCEEDED';
export const MACHINE_DOUBLE_BOOKED_ISSUE = 'MACHINE_DOUBLE_BOOKED';
export const EXCEEDED_ROOM_CAPACITY_ISSUE = 'EXCEEDED_ROOM_CAPACITY';
export const SPECIAL_OFFER_NOT_ALLOWED_TIME_ISSUE =
  'SPECIAL_OFFER_NOT_ALLOWED_TIME';
export const SPECIAL_OFFER_BEFORE_START_DATE_ISSUE =
  'SPECIAL_OFFER_BEFORE_START_DATE';
export const SPECIAL_OFFER_AFTER_END_DATE_ISSUE =
  'SPECIAL_OFFER_AFTER_END_DATE';
export const SPECIAL_OFFER_MAX_CONCURRENT_EXCEEDED_ISSUE =
  'SPECIAL_OFFER_MAX_CONCURRENT_EXCEEDED';
export const SPECIAL_OFFER_MAX_PER_DAY_EXCEEDED_ISSUE =
  'SPECIAL_OFFER_MAX_PER_DAY_EXCEEDED';
export const SPECIAL_OFFER_STAFF_EXCLUDED_ISSUE =
  'SPECIAL_OFFER_STAFF_EXCLUDED';
export const SPECIAL_OFFER_STAFF_NOT_INCLUDED_ISSUE =
  'SPECIAL_OFFER_STAFF_NOT_INCLUDED';
export const STAFF_REQUESTED_ISSUE = 'STAFF_REQUESTED';
export const STAFF_DOUBLE_BOOKED_ISSUE = 'STAFF_DOUBLE_BOOKED';
export const STAFF_NOT_WORKING_ISSUE = 'STAFF_NOT_WORKING';

export const ALL_ISSUES = [
  ...ACCEPTED_ISSUES,
  MACHINE_UNSUITABLE_ISSUE,
  ROOM_UNSUITABLE_ISSUE,
  STAFF_UNQUALIFIED_ISSUE,
  MIN_DAYS_BETWEEN_SESSIONS_NOT_EXCEEDED_ISSUE,
  MAX_DAYS_BETWEEN_SESSIONS_EXCEEDED_ISSUE,
  MACHINE_DOUBLE_BOOKED_ISSUE,
  EXCEEDED_ROOM_CAPACITY_ISSUE,
  SPECIAL_OFFER_NOT_ALLOWED_TIME_ISSUE,
  SPECIAL_OFFER_BEFORE_START_DATE_ISSUE,
  SPECIAL_OFFER_AFTER_END_DATE_ISSUE,
  SPECIAL_OFFER_MAX_CONCURRENT_EXCEEDED_ISSUE,
  SPECIAL_OFFER_MAX_PER_DAY_EXCEEDED_ISSUE,
  SPECIAL_OFFER_STAFF_EXCLUDED_ISSUE,
  SPECIAL_OFFER_STAFF_NOT_INCLUDED_ISSUE,
  STAFF_REQUESTED_ISSUE,
  STAFF_DOUBLE_BOOKED_ISSUE,
  STAFF_NOT_WORKING_ISSUE,
];

// When adding new view please follow the convention:
// {SINGLE|ALL}_{RESOURCE_NAME}_{DAY|WEEK}_VIEW
export const SINGLE_MACHINE_WEEK_VIEW = 'SINGLE_MACHINE_WEEK_VIEW';
export const SINGLE_ROOM_WEEK_VIEW = 'SINGLE_ROOM_WEEK_VIEW';
export const SINGLE_STAFF_WEEK_VIEW = 'SINGLE_STAFF_WEEK_VIEW';

export const SINGLE_MACHINE_DAY_VIEW = 'SINGLE_MACHINE_DAY_VIEW';
export const SINGLE_ROOM_DAY_VIEW = 'SINGLE_ROOM_DAY_VIEW';
export const SINGLE_STAFF_DAY_VIEW = 'SINGLE_STAFF_DAY_VIEW';

export const ALL_MACHINES_DAY_VIEW = 'ALL_MACHINES_DAY_VIEW';
export const ALL_ROOMS_DAY_VIEW = 'ALL_ROOMS_DAY_VIEW';
export const ALL_STAFF_DAY_VIEW = 'ALL_STAFF_DAY_VIEW';

export const DEFAULT_VIEW = ALL_STAFF_DAY_VIEW;

export const SUPPORTED_VIEWS = [
  SINGLE_MACHINE_WEEK_VIEW,
  SINGLE_ROOM_WEEK_VIEW,
  SINGLE_STAFF_WEEK_VIEW,

  // SINGLE_MACHINE_DAY_VIEW,
  // SINGLE_ROOM_DAY_VIEW,
  // SINGLE_STAFF_DAY_VIEW,

  ALL_MACHINES_DAY_VIEW,
  ALL_ROOMS_DAY_VIEW,
  ALL_STAFF_DAY_VIEW,
];

export const DEFAULT_ZOOM = '00:15:00';
export const DEFAULT_LIMIT_TIME_SLOTS_RANGE = 'true';
export const ZOOM_OPTIONS = [
  { slotDuration: '00:05:00', limitTimeSlotsRange: true },
  { slotDuration: '00:10:00', limitTimeSlotsRange: true },
  { slotDuration: '00:15:00', limitTimeSlotsRange: true },
  { slotDuration: '00:30:00', limitTimeSlotsRange: true },
  { slotDuration: '01:00:00', limitTimeSlotsRange: true },
  { slotDuration: '01:00:00', limitTimeSlotsRange: false },
];

export const STAFF_CALENDAR_MODE = {
  SINGLE_STAFF: 'SINGLE_STAFF',
  WORKING_STAFF: 'WORKING_STAFF',
  ALL_STAFF: 'ALL_STAFF',
};

/**
 * Get event portal key
 * @param {string} id
 * @param {Node} el
 * @returns {{isMirror: boolean, key: string}}
 */
export function eventPortalKey({ id }, isMirror = false) {
  let key = `${id}${isMirror ? ':mirror' : ''}`;

  return { key, isMirror };
}

let createDefaultTimeSlot = (date) => {
  return {
    daysOfWeek: [new Date(date).getUTCDay()],
    date,
    startTime: '00:00',
    endTime: '00:00',
  };
};

/**
 * Gets information about the selected week and sets the staff member business hours to full-calendar
 * @param {string} weekStartDate
 * @param {string} weekEndDate
 * @param {object} resource
 * @returns {array}
 */
export function getCurrentStaffBusinessHours(
  weekStartDate,
  weekEndDate,
  resource
) {
  let businessHours = [];
  let days = datesBetween(weekStartDate, weekEndDate, {
    inclusive: true,
  });

  days.forEach((day) => {
    let timeSlot = createDefaultTimeSlot(day);
    let workingDay = resource.calendarDays.find((d) => d.date === day);
    let workingTimeSlots = workingDay?.timeSlots;
    if (
      !workingDay?.__typename ||
      workingDay?.__typename === 'StaffCalendarDay'
    ) {
      workingTimeSlots = workingDay?.timeSlots.filter(
        (ts) => ts.type === 'WORKING'
      );
    }

    if (workingTimeSlots?.length > 0) {
      workingTimeSlots.forEach((ts) => {
        businessHours.push({
          ...timeSlot,
          ...ts,
        });
      });
    } else {
      businessHours.push(timeSlot);
    }
  });
  return businessHours;
}

/**
 * Calculates and returns startDate and endData for given view type.
 * @param {string} view
 * @param {object} fetchInfo
 * @returns {{endDate, startDate}}
 */
export function getDatesForViewQuery(view, fetchInfo) {
  let periodDelimiter = getPeriodForView(view);

  if (periodDelimiter === 'DAY') {
    let dayDate = dateFromJSDate(fetchInfo.start);

    return { startDate: dayDate, endDate: dayDate };
  } else {
    let startDate = dateFromJSDate(fetchInfo.start);
    let endDate = dateFromJSDate(fetchInfo.end);

    return { startDate, endDate };
  }
}

/**
 * Returns DAY or WEEK period delimiter for given view
 * @param {string} view
 * @returns {"DAY"|"WEEK"}
 */
export function getPeriodForView(view) {
  let nameParts = view.split('_');
  return nameParts[nameParts.length - 2] ?? 'WEEK';
}

/**
 * Returns MACHINE, ROOM, STAFF period delimiter for given view
 * @param {string} view
 * @returns {"MACHINE"|"ROOM"|"STAFF"}
 */
export function getResourceForView(view) {
  let nameParts = view.split('_');
  let namePart = nameParts[1];

  if (namePart === 'MACHINES') namePart = 'MACHINE';
  if (namePart === 'ROOMS') namePart = 'ROOM';

  return namePart;
}

/**
 * Returns machineId, roomId, staffId field name for filtering purposes for given view
 * @param {string} view
 * @param {boolean} [options]
 * @param {boolean} [options.shortStaff] - short staff field name (staffId) or long (staffMemberId)?
 * @returns {"machineId"|"roomId"|"staffId"}
 */
export function getResourceIdField(view, { shortStaff = true } = {}) {
  const resourceType = getResourceForView(view);

  switch (resourceType) {
    case 'MACHINE':
      return 'machineId';
    case 'ROOM':
      return 'roomId';
    case 'STAFF':
    default:
      return shortStaff ? 'staffId' : 'staffMemberId';
  }
}

/**
 * Verifies if given view is day view showing all staff/rooms/machines
 * @param {string} view
 * @returns {boolean}
 */
export function isAllResourcesDayView(view) {
  return [
    ALL_MACHINES_DAY_VIEW,
    ALL_ROOMS_DAY_VIEW,
    ALL_STAFF_DAY_VIEW,
  ].includes(view);
}

/**
 * Verifies if given view is day's view
 * @param {string} view
 * @returns {boolean}
 */
export function isDayView(view) {
  return [
    SINGLE_MACHINE_DAY_VIEW,
    SINGLE_ROOM_DAY_VIEW,
    SINGLE_STAFF_DAY_VIEW,
    ALL_MACHINES_DAY_VIEW,
    ALL_ROOMS_DAY_VIEW,
    ALL_STAFF_DAY_VIEW,
  ].includes(view);
}

/**
 * Verifies if given view is a staff view
 * @param {string} view
 * @returns {boolean}
 */
export function isStaffView(view) {
  return [
    SINGLE_STAFF_WEEK_VIEW,
    SINGLE_STAFF_DAY_VIEW,
    ALL_STAFF_DAY_VIEW,
  ].includes(view);
}

/**
 * Verifies if given view is one of machines' views
 * @param {string} view
 * @returns {boolean}
 */
export function isMachineView(view) {
  return [
    SINGLE_MACHINE_WEEK_VIEW,
    SINGLE_MACHINE_DAY_VIEW,
    ALL_MACHINES_DAY_VIEW,
  ].includes(view);
}

/**
 * Verifies if given view is one of rooms' views
 * @param {string} view
 * @returns {boolean}
 */
export function isRoomView(view) {
  return [
    SINGLE_ROOM_WEEK_VIEW,
    SINGLE_ROOM_DAY_VIEW,
    ALL_ROOMS_DAY_VIEW,
  ].includes(view);
}

/**
 * Verifies if given view is a week view
 * @param {string} view
 * @returns {boolean}
 */
export function isWeekView(view) {
  return [
    SINGLE_MACHINE_WEEK_VIEW,
    SINGLE_ROOM_WEEK_VIEW,
    SINGLE_STAFF_WEEK_VIEW,
  ].includes(view);
}

export function isValidLocalDateQueryParam(dateString) {
  if (typeof dateString !== 'string') return false;

  // Accepts only dates in format: yyyy-mm-dd, e.g. 2022-03-30
  const regex = new RegExp(
    '^\\d{4}\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])$'
  );
  return regex.test(dateString);
}

/**
 * Verifies if given view is supported by us
 * @param {string} view
 * @returns {boolean}
 */
export function isViewSupported(view) {
  return SUPPORTED_VIEWS.includes(view);
}

/**
 * Gets raw response from the server and converts to Events that FullCalendar understands.
 * @param {array} rawResources
 * @param {object} options
 * @param {string} options.view
 * @param {string} options.startDate
 * @param {string} options.endDate
 * @returns {*}
 */
export function prepareCalendarEventsData(
  rawResources,
  { view, startDate, endDate }
) {
  let events = {};
  rawResources.forEach((resource) => {
    resource.calendarDays.forEach((calendarDay) => {
      if (calendarDay.date < startDate || calendarDay.date > endDate) {
        return;
      }

      calendarDay.events.forEach((calendarEvent) => {
        const resourceId = `${calendarEvent.__typename}::${calendarEvent.id}`;
        if (events[resourceId]) {
          return;
        }

        const staffMember = rawResources.find(
          (rawResource) =>
            rawResource.id === calendarEvent.staffMemberId &&
            rawResource.__typename === 'Staff'
        );

        const room = rawResources.find(
          (rawResource) =>
            rawResource.id === calendarEvent.room?.id &&
            rawResource.__typename === 'Room'
        );

        const machine = rawResources.find(
          (rawResource) =>
            rawResource.id === calendarEvent.machine?.id &&
            rawResource.__typename === 'Machine'
        );

        const event = CalendarEvent.fromData(
          {
            resourceType: resource.__typename,
            staffMember,
            room,
            machine,
            ...calendarEvent,
          },
          view
        );

        if (!variation('release-waitlist-holding-slot-events')) {
          if (event.isWaitlistHoldingSlot) {
            return;
          }
        }

        events[resourceId] = event.toFCEvent();
      });
    });
  });

  return Object.values(events);
}

/**
 * Gets raw response from the server and converts to Resources that FullCalendar understands.
 * @param {array} rawResources
 * @param {string} dayDate
 * @returns {*}
 */
export function prepareCalendarResourcesData(rawResources, dayDate) {
  return rawResources.map((resource) => {
    let calendarDay = resource.calendarDays.find((day) => day.date === dayDate);
    let businessHours = [];

    if (
      !calendarDay.__typename ||
      calendarDay.__typename === 'StaffCalendarDay'
    ) {
      calendarDay.timeSlots.forEach((timeSlot) => {
        let { startTime, endTime } = timeSlot;
        if (timeSlot.type === 'WORKING') {
          businessHours.push({
            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
            startTime,
            endTime,
          });
        }
      });
    }

    if (
      calendarDay.__typename === 'RoomCalendarDay' ||
      calendarDay.__typename === 'MachineCalendarDay'
    ) {
      calendarDay.timeSlots.forEach((timeSlot) => {
        let { startTime, endTime } = timeSlot;
        businessHours.push({
          daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
          startTime,
          endTime,
        });
      });
    }

    if (businessHours.length === 0) {
      businessHours.push({
        daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
        startTime: '0:00',
        endTime: '0:00',
      });
    }

    let id = `${resource.__typename}::${resource.id}`;
    return {
      id,
      businessHours,
      extendedProps: {
        ...resource,
      },
    };
  });
}

export function findEventsBy({ staffMemberId, date }) {
  return (data) => {
    let staffMember = A(data.staffCalendar).findBy('id', staffMemberId);
    return A(staffMember.calendarDays).findBy('date', date).events;
  };
}

export function setZoomStyles(zoom) {
  let calendarGrid = document.querySelector(
    '[data-appointment-calendar-container].fc .fc-scrollgrid-section-body'
  );

  let zoomClasses = {
    '01:00:00': 'zoom-60-minutes',
    '00:30:00': 'zoom-30-minutes',
    '00:15:00': 'zoom-15-minutes',
    '00:10:00': 'zoom-10-minutes',
    '00:05:00': 'zoom-5-minutes',
  };

  calendarGrid.classList.remove(...Object.values(zoomClasses));

  let zoomClass = zoomClasses[zoom];
  assert(`Invalid zoom setting received: ${zoomClass}`, zoomClass);

  calendarGrid.classList.add(zoomClass);
}

export function changeTimeForRelatedAppointments(data, fullCalendarApi) {
  let deltaMin = data.delta.milliseconds / 1000 / 60;
  return getRelatedAppointments(data, fullCalendarApi).map((appointment) => {
    return {
      id: CalendarEvent.fromFCEvent(appointment).id,
      startTime: addTime(timeFromJSDate(appointment.start), deltaMin),
      date: dateFromJSDate(data.event.start),
    };
  });
}

export function getRelatedAppointments(data, fullCalendarApi) {
  let { clientId, date, serviceGroupId } = data.event.extendedProps;
  if (!fullCalendarApi) return [];

  let relatedAppointments = fullCalendarApi
    .getEvents()
    .filter(
      (appointment) =>
        appointment.extendedProps.clientId === clientId &&
        appointment.extendedProps.date === date &&
        appointment.id !== data.event.id
    );

  if (serviceGroupId) {
    relatedAppointments = relatedAppointments.filter(
      (appointment) =>
        appointment.extendedProps.serviceGroupId === serviceGroupId
    );
  }

  return relatedAppointments;
}

export function getPreviousValues(data, fullCalendarApi) {
  if (!data.oldEvent) {
    return null;
  }
  let relatedAppointmentsValues = getRelatedAppointments(
    data,
    fullCalendarApi
  ).map((appointment) => {
    return {
      id: CalendarEvent.fromFCEvent(appointment).id,
      date: appointment.extendedProps.date,
      machineId: appointment.extendedProps.machine?.id,
      roomId: appointment.extendedProps.room?.id,
      staffMemberId: appointment.extendedProps.staffMemberId,
      startTime: timeFromJSDate(appointment.start),
    };
  });

  return [
    {
      id: CalendarEvent.fromFCEvent(data.oldEvent).id,
      date: data.oldEvent.extendedProps.date,
      machineId: data.oldEvent.extendedProps.machine?.id,
      roomId: data.oldEvent.extendedProps.room?.id,
      staffMemberId: data.oldEvent.extendedProps.staffMemberId,
      startTime: timeFromJSDate(data.oldEvent.start),
      ...prepareResourceVariable(data.oldResource),
    },
    ...relatedAppointmentsValues,
  ];
}

export function prepareResourceVariable(resource) {
  if (resource) {
    let [type, resourceId] = resource.id.split('::');
    if (type === 'Machine') {
      return { machineId: resourceId };
    } else if (type === 'Room') {
      return { roomId: resourceId };
    } else if (type === 'Staff') {
      return { staffMemberId: resourceId };
    }
  }
  return {};
}
