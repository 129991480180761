import Service, { service } from '@ember/service';
import { addListener, removeListener } from '@ember/object/events';
import { queryManager } from 'ember-apollo-client';
import { variation } from 'ember-launch-darkly';
import basketCheckoutChange from 'my-phorest/gql/subscriptions/basket-checkout-change.graphql';
import basketChange from 'my-phorest/gql/subscriptions/basket-change.graphql';
import calendarChange from 'my-phorest/gql/subscriptions/calendar-change.graphql';
import payrollRunDone from 'my-phorest/gql/subscriptions/payroll-run-done.graphql';
import appointmentsQuery from 'my-phorest/gql/queries/appointments.graphql';
import breaksQuery from 'my-phorest/gql/queries/breaks.graphql';
import { capitalize } from '@ember/string';
import * as calendarCache from 'my-phorest/utils/calendar-cache';
import basketQuery from 'my-phorest/gql/queries/basket.graphql';

export default class ApolloSubscriptions extends Service {
  @queryManager apollo;
  @service eventPropagator;
  @service versionTracker;

  calendarChangeSubscription = null;
  basketCheckoutChangeSubscription = null;
  basketChangeSubscription = null;
  payrollRunDoneSubscription = null;

  constructor() {
    super(...arguments);

    this._handleBasketCheckoutNotification =
      this.handleBasketCheckoutNotification.bind(this);

    this._handleCalendarNotification =
      this.handleCalendarNotification.bind(this);

    this._handleBasketChangeNotification =
      this.handleBasketChangeNotification.bind(this);

    this._handlePayrollRunDoneNotification =
      this.handlePayrollRunDoneNotification.bind(this);
  }

  async handleBasketChangeNotification(event) {
    if (this.#shouldIgnoreNotification(event.sourceApplication)) return;
    this._fetchBasket(event.resource.id);
  }

  async handleBasketCheckoutNotification(event) {
    const { action, resource, error } = event;
    this.eventPropagator.broadcast('basketCheckoutChange', {
      action,
      resourceType: resource.type,
      error,
    });
  }

  async handlePayrollRunDoneNotification(event) {
    this.eventPropagator.broadcast('payrollRunDone', event);
  }

  async handleCalendarNotification(event) {
    if (this.#shouldIgnoreNotification(event.sourceApplication)) return;

    const { action, resource } = event;
    let data;
    if (action === 'CREATED' || action === 'UPDATED') {
      if (resource.type === 'APPOINTMENT') {
        data = await this._fetchAppointment(resource.id);
      }
      if (resource.type === 'BREAK') {
        data = await this._fetchBreak(resource.id);
      }
    } else if (action === 'DELETED' || action === 'CANCELLED') {
      let __typename;
      if (resource.type === 'APPOINTMENT') {
        __typename = 'Appointment';
      } else if (resource.type === 'BREAK') {
        __typename = 'Break';
      } else {
        return;
      }
      data = [{ __typename, id: resource.id }];
    }

    const { cache } = this.apollo.apolloClient;
    this.updateCalendarCache(cache, data, action, resource.type);
    this.eventPropagator.broadcast('calendarChange', {
      data,
      cache,
      action,
      resourceType: resource.type,
    });
  }

  updateCalendarCache(cache, data, action, resourceType) {
    if (action === 'CREATED') {
      data.forEach((event) => {
        const eventType = capitalize(resourceType.toLowerCase()),
          eventId = event.id,
          date = event.date;

        if (event.staffMemberId) {
          calendarCache.addEvent(cache, {
            date,
            eventId,
            eventType,
            resourceId: event.staffMemberId,
          });
        }
        if (event.machine?.id) {
          calendarCache.addEvent(cache, {
            date,
            eventId,
            eventType,
            resourceId: event.machine.id,
            resourceType: 'Machine',
          });
        }
        if (event.room?.id) {
          calendarCache.addEvent(cache, {
            date,
            eventId,
            eventType,
            resourceId: event.room.id,
            resourceType: 'Room',
          });
        }
      });
    }

    if (action === 'DELETED' || action === 'CANCELLED') {
      data.forEach(({ id, __typename }) => {
        const normalizedId = cache.identify({ id, __typename });
        if (normalizedId) {
          cache.evict({ id: normalizedId });
        }
      });
    }

    cache.evict({ id: 'ROOT_QUERY', fieldName: 'appointmentSearch' });
    cache.gc();
  }

  async subscribe(subscriptionName, variables = {}) {
    if (subscriptionName === 'basketCheckoutChange') {
      this.basketCheckoutChangeSubscription = await this.apollo.subscribe(
        { query: basketCheckoutChange, variables },
        'basketCheckoutChange'
      );

      addListener(
        this.basketCheckoutChangeSubscription,
        'event',
        this._handleBasketCheckoutNotification
      );
    }

    if (subscriptionName === 'calendarChange') {
      this.calendarChangeSubscription = await this.apollo.subscribe(
        { query: calendarChange, variables },
        'calendarChange'
      );

      addListener(
        this.calendarChangeSubscription,
        'event',
        this._handleCalendarNotification
      );
    }

    if (subscriptionName === 'basketChange') {
      this.basketChangeSubscription = await this.apollo.subscribe(
        { query: basketChange, variables },
        'basketChange'
      );

      addListener(
        this.basketChangeSubscription,
        'event',
        this._handleBasketChangeNotification
      );
    }

    if (subscriptionName === 'payrollRunDone') {
      this.payrollRunDoneSubscription = await this.apollo.subscribe(
        { query: payrollRunDone, variables },
        'payrollRunDone'
      );

      addListener(
        this.payrollRunDoneSubscription,
        'event',
        this._handlePayrollRunDoneNotification
      );
    }
  }

  unsubscribe(subscriptionName) {
    if (
      subscriptionName === 'basketCheckoutChange' &&
      this.basketCheckoutChangeSubscription
    ) {
      this.basketCheckoutChangeSubscription.apolloUnsubscribe();
      removeListener(
        this.basketCheckoutChangeSubscription,
        'event',
        this._handleBasketCheckoutNotification
      );
      this.basketCheckoutChangeSubscription = null;
    }

    if (
      subscriptionName === 'calendarChange' &&
      this.calendarChangeSubscription
    ) {
      this.calendarChangeSubscription.apolloUnsubscribe();
      removeListener(
        this.calendarChangeSubscription,
        'event',
        this._handleCalendarNotification
      );
      this.calendarChangeSubscription = null;
    }

    if (subscriptionName === 'basketChange' && this.basketChangeSubscription) {
      this.basketChangeSubscription.apolloUnsubscribe();
      removeListener(
        this.basketChangeSubscription,
        'event',
        this._handleBasketChangeNotification
      );
      this.basketChangeSubscription = null;
    }
  }

  unsubscribeAll() {
    this.unsubscribe('basketCheckoutChange');
    this.unsubscribe('calendarChange');
    this.unsubscribe('basketChange');
  }

  async _fetchAppointment(id) {
    const { appointments } = await this.apollo.query({
      query: appointmentsQuery,
      variables: {
        filterBy: {
          ids: [id],
        },
      },
      fetchPolicy: 'network-only',
    });

    if (appointments) {
      return appointments.edges.map((edge) => edge.node);
    }
    return null;
  }

  async _fetchBreak(id) {
    const { breaks } = await this.apollo.query({
      query: breaksQuery,
      variables: {
        filterBy: {
          ids: [id],
        },
      },
      fetchPolicy: 'network-only',
    });

    if (breaks) {
      return breaks.edges.map((edge) => edge.node);
    }
    return null;
  }

  /**
   * Ignores notifications that were caused by this application instance.
   *
   * @param sourceApplication
   * @param {string} sourceApplication.id - application id (sent to the server with every request via 'x-phorest-application-id' header)
   * @param {string} sourceApplication.instanceId - application instance id (sent to the server with every request via 'x-phorest-application-instance-id' header)
   * @returns {boolean}
   */
  #shouldIgnoreNotification(sourceApplication) {
    if (!variation('ops-ws-calendar-ignore-self-notifications')) return false;
    if (!sourceApplication) return false;

    return (
      sourceApplication.id === 'my-phorest' &&
      sourceApplication.instanceId === this.versionTracker.applicationInstanceId
    );
  }

  async _fetchBasket(id) {
    await this.apollo.query(
      {
        query: basketQuery,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      },
      'basket'
    );
  }
}
