import Service, { service } from '@ember/service';
import { isTesting } from '@embroider/macros';
import { htmlSafe } from '@ember/template';
import { v4 as uuid } from 'uuid';
import Ember from 'ember';

import * as Sentry from '@sentry/browser';
import config from 'my-phorest/config/environment';

const EXPECTED_SERVER_ERROR_NAME = 'ServerError';
const NETWORK_ERROR_MESSAGE = 'Network request failed';
const AVAILABLE_SENTRY_LEVELS = [
  'fatal',
  'critical',
  'error',
  'warning',
  'log',
  'info',
  'debug',
];
const PHOREST_TRACE_ID_RESPONSE_HEADER = 'X-Phorest-Trace-Id';

export default class ErrorHandlerService extends Service {
  @service intl;
  @service notifications;

  createHoneycombTags(error) {
    const traceId = error?.response?.headers?.get(
      PHOREST_TRACE_ID_RESPONSE_HEADER
    );
    if (!traceId) return;

    const { honeycombLink } = config;
    const now = new Date();
    const twoDays = 2 * 24 * 60 * 60 * 1000;
    const twoDaysAgo = new Date(now.getTime() - twoDays);
    const inTwoDays = new Date(now.getTime() + twoDays);

    const traceStart = Math.floor(twoDaysAgo.getTime() / 1000);
    const traceEnd = Math.floor(inTwoDays.getTime() / 1000);

    return {
      traceId,
      honeycombUrl: `${honeycombLink}/trace?trace_id=${traceId}&trace_start_ts=${traceStart}&trace_end_ts=${traceEnd}`,
    };
  }

  customEmberOnError(error) {
    this.handle(error);
  }

  /**
   * Handles errors by normalizing them, sending to Sentry and showing an error notification to the user.
   *
   * @param {object} error
   * @param {object} [options]
   * @param {boolean} [options.notifySentry=true]
   * @param {boolean} [options.showError=true]
   * @param {string} [options.sentryLevel]
   * @param {array} [options.sentryExtras]
   * @param {array} [options.sentryTags]
   * @returns {*|string}
   */
  handle(error, options = {}) {
    const notifySentry = options.notifySentry ?? true;
    const showError = options.showError ?? true;
    const errorCode = uuid();
    const honeycombTags = this.createHoneycombTags(error);
    const sentryTags = this.prepareSentryTags(
      honeycombTags,
      options.sentryTags
    );

    if (
      Array.isArray(error?.errors) ||
      Array.isArray(error?.graphQLErrors) ||
      error?.networkError // Despite the name, it's more an issue with the server we hit, rather than user network
    ) {
      const errors = this.#normalizeServerErrors(error);

      if (notifySentry) {
        errors.forEach(([normalizedError, rawServerError]) => {
          this.notifySentryAboutError(normalizedError, errorCode, {
            context: JSON.stringify(rawServerError, null, 2),
            fullError: JSON.stringify(error, null, 2),
            level: options.sentryLevel,
            extras: options.sentryExtras,
            tags: sentryTags,
          });
        });
      }

      if (showError) {
        this.showErrorNotification(error, errorCode);
      }
    } else if (error?.message !== NETWORK_ERROR_MESSAGE) {
      // These are handled errors than may come from faulty DOM operations for example.
      // There's no need to show a notification but Sentry should be informed about the issue.
      this.notifySentryAboutError(error, errorCode, {
        fullError: error,
        level: options.sentryLevel,
        extras: options.sentryExtras,
        tags: sentryTags,
      });
    }

    if (this.rethrowErrorForQunit()) {
      // Using Ember.onerror for global error handling needs special care in tests because of how ember-qunit was designed
      // Read more here: https://github.com/emberjs/ember-qunit/pull/304
      // eslint-disable-next-line no-console
      console.log(
        'ErrorHandler: error was rethrown for QUnit to consume. If you are throwing a fake error, make sure it has a correct structure to be picked up by the global error handler and stub `errorHandler.rethrowErrorForQunit` method to return `false`'
      );
      throw error;
    }

    // Network issues fall here. We are not offline-first yet but we won't
    // show a failure notification. We already show disconnection bar.

    return errorCode;
  }

  initialize() {
    Ember.onerror = this.customEmberOnError.bind(this);
  }

  notifySentryAboutError(
    error,
    errorCode,
    { context, fullError, level, extras, tags } = { level: 'error', extras: [] }
  ) {
    console.error(error);

    Sentry.withScope((scope) => {
      // Additional context for the error. This is scoped to a particular issue (see `fullError` if you need more information)
      if (context) {
        scope.setExtra('context', context);
      }

      // Error passed to the `errorHandler` before chunking and categorization we do
      if (fullError) {
        scope.setExtra('fullError', fullError);
      }

      if (level !== 'error' && AVAILABLE_SENTRY_LEVELS.includes(level)) {
        // error is the default level, no need to set it
        scope.setLevel(level);
      }

      if (extras?.length > 0) {
        extras.forEach(({ key, value }) => {
          scope.setExtra(key, value);
        });
      }

      if (typeof tags === 'object' && tags !== null) {
        scope.setTags(tags);
      }

      Sentry.captureException(error, {
        tags: {
          'ui.errorCode': errorCode,
        },
      });
    });
  }

  prepareSentryTags(honeycombTags, extraSentryTags) {
    if (!honeycombTags && !extraSentryTags) return;

    let sentryTags = {};

    if (honeycombTags) {
      sentryTags = { ...sentryTags, ...honeycombTags };
    }

    if (extraSentryTags) {
      sentryTags = { ...sentryTags, ...extraSentryTags };
    }

    return sentryTags;
  }

  rethrowErrorForQunit() {
    return isTesting();
  }

  showErrorNotification(error, errorCode) {
    const isValidationError = error?.errors?.[0]?.extensions?.validationCode;
    const serverMessage = error?.errors?.[0]?.message;
    const showServerMessage = isValidationError && !!serverMessage;

    const notificationMessage = showServerMessage
      ? htmlSafe(serverMessage)
      : this.intl.t('global.error-notification-id', {
          errorCode,
        });

    this.notifications.failure(
      this.intl.t('global.error-notification-title'),
      notificationMessage,
      {
        sticky: true,
      }
    );
  }

  /**
   * Changes name of an error if we know a path in the GraphQL query that failed.
   * In case of mutations, this will be most likely the name of a mutation, like "GraphQlError @ rescheduleAppointments".
   * In case of queries, this will be a path to a field that failed, like "GraphQLError @ serviceHistory.0.branch".
   *
   * Issues with Bad User Input on the other hand, have no path. In that case we add the message to the error name,
   * like `GraphQLError $$ Variable "$clientId" of required type "ID!" was not provided.`
   *
   * This makes error names more unique and help Sentry categorize them better.
   *
   * @param {object} errorToSend
   * @param {object} rawServerError
   * @param {array} [rawServerError.path]
   * @param {string} [rawServerError.message]
   * @param {object} [rawServerError.extensions]
   * @param {string} [rawServerError.extensions.code]
   */
  #decorateErrorName(errorToSend, rawServerError) {
    if (Array.isArray(rawServerError.path)) {
      errorToSend.name += ' @ ' + rawServerError.path.join('.');
    } else if (rawServerError?.extensions?.code === 'BAD_USER_INPUT') {
      errorToSend.name += ' $$ ' + rawServerError.message;
    }
  }

  /**
   * Categorizes and normalizes server errors to throw them to Sentry.
   * We know of 3 structures of errors.
   *
   * All errors are returned by the server in a very similar structure. Apollo Client does a bit of magic
   * and changes the structure of errors. It looks like it's done for easier usage on the FE side.
   * [According to the docs](https://www.apollographql.com/docs/react/data/error-handling/#graphql-errors)
   * it seems that `error.graphQLErrors` should be the place where errors are defined.
   *
   * (1st type) A problem with resolving a field follows exactly that. It happens for example when `api-facade` can't load
   * a relationship because the expected "id field" is null. That's a resolver error (loader error).
   *
   * (2nd type) Sometimes the actual GraphQL errors are hidden in a more nested structure (`error.errors[].result.errors[]`).
   * This happens when we make a syntax error in the query (for example).
   *
   * (3rd type) Another type of error is unexpected one. It happens when `core` failed to do the request.
   * `api-facade` has its own error handler that is responsible for wrapping `core` errors but sometimes we get something
   * that we didn't predict. It might be for example something not supported yet by the Basket API.
   *
   * @private
   * @param {object} error
   * @param {array} [error.errors]
   * @param {array} [error.graphQLErrors]
   * @param {object} [error.networkError]
   * @returns {array} - array of arrays. Item structure: [normalizedError, rawServerError]
   */
  #normalizeServerErrors(error) {
    const errors = [];

    if (Array.isArray(error?.graphQLErrors)) {
      error.graphQLErrors.forEach((rawServerError) => {
        const graphQlError = new GraphQLError(rawServerError.message, {
          cause: rawServerError,
        });
        this.#decorateErrorName(graphQlError, rawServerError);
        errors.push([graphQlError, rawServerError]);
      });
    }

    if (Array.isArray(error?.errors)) {
      error.errors.forEach((rawServerError) => {
        if (
          rawServerError.name === EXPECTED_SERVER_ERROR_NAME &&
          Array.isArray(rawServerError?.result?.errors)
        ) {
          // Expected GraphQL errors like syntax or validation error
          rawServerError.result.errors.forEach((rawServerError) => {
            const graphQlError = new GraphQLError(rawServerError.message, {
              cause: rawServerError,
            });
            this.#decorateErrorName(graphQlError, rawServerError);
            errors.push([graphQlError, rawServerError]);
          });
        } else {
          // Unexpected server error like internal core issues
          // Or 401s (they have `name: "ServerError"` [`EXPECTED_SERVER_ERROR_NAME`] but no granular `errors` array)
          const graphQLError = new GraphQLError(rawServerError.message, {
            cause: rawServerError,
          });
          this.#decorateErrorName(graphQLError, rawServerError);
          errors.push([graphQLError, rawServerError]);
        }
      });
    }

    if (error.networkError) {
      const url = this.#safeGetUrl(error.networkError?.response?.url);
      const host = url?.host;
      const hostMessage = host ? `host: ${host}` : 'retrieving host failed';
      const serverError = new ServerError(
        `[${error.networkError.statusCode}] Request failed due to a server or network issue (${hostMessage})`,
        {
          cause: error.networkError,
        }
      );
      serverError.name += ` (${error.networkError.name})`;
      errors.push([serverError, error.networkError]);
    }

    return errors;
  }

  #safeGetUrl(error) {
    try {
      return new URL(error.networkError?.response?.url);
    } catch (e) {
      // fail gracefully if URL can't be constructed
      return '';
    }
  }
}

export class GraphQLError extends Error {
  name = 'GraphQLError';
}

export class GraphQLWarning extends Error {
  name = 'GraphQLWarning';
}

export class ServerError extends Error {
  name = 'ServerError';
}

export class WebSocketsWarning extends Error {
  name = 'WebSocketsWarning';
}

export class WebSocketsDebug extends Error {
  name = 'WebSocketsDebug';
}
