import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { TrackedObject } from 'tracked-built-ins';
import { addMoney, sumMoneyCollection } from 'my-phorest/utils/currency';
import { subtractMoney } from 'my-phorest/utils/currency';
import { isPresent } from '@ember/utils';

export const INTEGRATED_CARD_PROVIDERS = {
  BANCARD: 'BANCARD',
  STRIPE: 'STRIPE',
};

export const INTEGRATED_PAYMENT_METHOD_TYPES = {
  CARD: 'CARD',
  INTERAC_PRESENT: 'INTERAC_PRESENT',
  TERMINAL: 'TERMINAL',
  UNKNOWN: 'UNKNOWN',
};

export const PAYMENT_METHODS = {
  CASH: 'CASH',
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
  CHEQUE: 'CHEQUE',
  VOUCHER: 'VOUCHER',
  OTHER: 'OTHER',
  STORED_CARD: 'STORED_CARD',
  TERMINAL: 'TERMINAL',
  ACCOUNT: 'ACCOUNT',
};

export const isPhorestPayEnabled = function ({
  creditCardProvider,
  creditCardIntegrationEnabled,
}) {
  const { BANCARD, STRIPE } = INTEGRATED_CARD_PROVIDERS;

  return (
    (creditCardProvider === BANCARD || creditCardProvider === STRIPE) &&
    creditCardIntegrationEnabled
  );
};

export const isStripeBranch = function ({ creditCardProvider }) {
  return creditCardProvider === INTEGRATED_CARD_PROVIDERS.STRIPE;
};

export const canTakeInHouseDeposits = function (creditCardProvider) {
  return creditCardProvider === INTEGRATED_CARD_PROVIDERS.STRIPE;
};

export const calculateAccountMoneyLimit = function (basket) {
  if (
    Number(basket.client.creditTerms?.creditLimit) <=
    Number(basket.client.creditAccount?.outstandingBalance)
  ) {
    return 0;
  }

  return subtractMoney(
    basket.client.creditTerms?.creditLimit,
    basket.client.creditAccount?.outstandingBalance
  );
};

export const paymentMethodsLimiter = function (settings, methods) {
  let { CASH, CREDIT, DEBIT, CHEQUE, VOUCHER, OTHER, TERMINAL, ACCOUNT } =
    PAYMENT_METHODS;

  let acceptedPaymentMethods = [CASH, CHEQUE, VOUCHER, OTHER, ACCOUNT];

  if (isPhorestPayEnabled(settings)) {
    acceptedPaymentMethods.push(TERMINAL);
  } else {
    acceptedPaymentMethods.push(CREDIT, DEBIT);
  }

  return methods.filter(
    (m) => !m.archived && acceptedPaymentMethods.includes(m.paymentType)
  );
};

export const storedCardsLimiter = function (settings, cards) {
  const validCards = [];

  if (settings.storedCardPaymentEnabled) {
    validCards.push(...cards.storedCards);
  }

  return validCards;
};

const PAYMENT_MODES = {
  GROUPON: 'GROUPON',
};

export class BalanceElement {
  @tracked value = 0;

  constructor(id, { label, labelKey, type, builtIn, paymentMode }) {
    this.id = id;
    this.label = label;
    this.labelKey = labelKey;
    this.type = type;
    this.builtIn = builtIn;
    this.paymentMode = paymentMode;
  }

  @action
  addToValue(value) {
    this.value = addMoney(this.value, value);
  }

  @action
  setValue(value) {
    this.value = addMoney(0, value);
  }

  @action
  clearValue() {
    this.value = 0;
  }

  processBalance(balance) {
    return addMoney(balance, this.value);
  }

  serialize() {
    let serialized = {
      type: `${this.type}_PAYMENT`.toUpperCase(),
      amount: Number(this.value),
    };

    if (this.type === PAYMENT_METHODS.OTHER) {
      serialized.methodId = this.id;
      if (this.isGroupon) {
        serialized.grouponCode = this.grouponCode;
      }
    }

    return serialized;
  }

  get isGroupon() {
    return this.paymentMode === PAYMENT_MODES.GROUPON;
  }
}

export class StoredCard extends BalanceElement {
  integratedPayment;
  @tracked disabled;

  constructor({ id, cardNumber, cardType, preferred, terminalId }) {
    super(id, {
      type: PAYMENT_METHODS.STORED_CARD,
    });
    this.cardNumber = cardNumber.slice(-4);
    this.cardType = cardType;
    this.preferred = preferred;
    this.terminalId = terminalId;
    this.disabled = false;
  }

  serialize() {
    let serialized = super.serialize();

    if (this.integratedPayment) {
      serialized.id = this.integratedPayment.id;
      serialized.type = this.integratedPayment.type;
      return serialized;
    }

    if (this.terminalId) {
      serialized.terminalId = this.terminalId;
    }
    serialized.paymentSourceId = parseInt(this.id);
    return serialized;
  }
}

export class NewStripeCard extends BalanceElement {
  constructor({ id }) {
    super(id, {
      type: INTEGRATED_CARD_PROVIDERS.STRIPE,
    });
  }

  serialize() {
    let serialized = super.serialize();
    serialized.cardToken = this.id;
    serialized.saveCardDetails = this.saveCardDetails;
    return serialized;
  }
}

export class TerminalPayment extends BalanceElement {
  saveCardDetails = false;
  terminalId;
  @tracked cards = [];

  constructor(method) {
    super(PAYMENT_METHODS.TERMINAL, {
      type: PAYMENT_METHODS.TERMINAL,
      labelKey: PAYMENT_METHODS.TERMINAL,
      builtIn: true,
    });
    this.saveCardDetails = method.saveCardDetails ?? false;
  }

  serialize() {
    return this.cards.map((card) => {
      let payload = {
        saveCardDetails: card.saveCardDetails ?? this.saveCardDetails,
        terminalId: card.terminalId ?? this.terminalId,
        type:
          card.integratedPaymentType ??
          `${PAYMENT_METHODS.TERMINAL}_PAYMENT`.toUpperCase(),
        amount: Number(card.value),
      };
      if (card.integratedPaymentId) {
        payload.id = card.integratedPaymentId;
      }
      return payload;
    });
  }

  addCard(card) {
    this.cards = [...this.cards, new TrackedObject(card)];
  }

  addValue(value) {
    this.cards = [
      ...this.cards,
      new TrackedObject({ value: addMoney(0, value) }),
    ];
  }

  @action
  removeCardAtIndex(cardIndex) {
    this.cards.splice(cardIndex, 1);
    this.cards = [...this.cards];
  }

  clearValue() {
    this.cards = this.cards
      .filter((card) => isPresent(card.integratedPaymentId))
      .map((card) => {
        card.value = 0;
        return card;
      });
  }

  get value() {
    return this.cards.reduce((sum, card) => addMoney(sum, card.value), 0);
  }
}

class ChosenVoucher {
  @tracked
  value;

  constructor({ id, remainingBalance, serial }, value) {
    this.id = id;
    this.remainingBalance = remainingBalance;
    this.serial = serial;
    this.value = value;
  }
}

export class Voucher extends BalanceElement {
  @tracked
  chosenVouchers = [];

  constructor({ id, paymentType }) {
    super(id, {
      type: PAYMENT_METHODS.VOUCHER,
      labelKey: paymentType,
      builtIn: true,
    });
  }

  useVoucher(newValue, voucher) {
    let existing = this.chosenVouchers.find((v) => v.id === voucher.id);
    if (existing) {
      existing.value = newValue;
    } else {
      this.chosenVouchers = [
        ...this.chosenVouchers,
        new ChosenVoucher(voucher, newValue),
      ];
    }
  }

  removeVoucher(voucher) {
    let filtered = this.chosenVouchers.filter((v) => v.id !== voucher.id);
    this.chosenVouchers = [...filtered];
  }

  clearValue() {
    this.chosenVouchers.forEach((voucher) => {
      voucher.value = 0;
    });
  }

  get value() {
    return sumMoneyCollection(this.chosenVouchers, 'value');
  }

  serialize() {
    return this.chosenVouchers
      .filter((v) => Number(v.value) !== 0)
      .map((voucher) => {
        return {
          amount: Number(voucher.value),
          voucherId: voucher.id,
          type: `${this.type}_PAYMENT`,
        };
      });
  }
}

class AccountMoney extends BalanceElement {
  @tracked _value = 0;

  constructor(method) {
    super(method.id, {
      labelKey: method.paymentType,
      type: method.paymentType,
      builtIn: true,
    });
  }

  get value() {
    return this._value;
  }

  set value(value) {
    if (Number(value) > Number(this.limit)) {
      this._value = this.limit;
    } else {
      this._value = value;
    }
  }
}

export class Balance {
  @tracked elements = [];

  addPaymentMethods(methods) {
    methods
      .filter((method) => method.builtIn)
      .forEach((m) => this._addDefaultElement(m));
    (methods.filter((method) => !method.builtIn) || []).forEach((method) =>
      this._addCustomElement(method)
    );
  }

  addStoredCards(cards = [], { creditCardProvider } = {}) {
    cards.forEach((c) => {
      if (c.provider === creditCardProvider) {
        this.elements = [...this.elements, new StoredCard(c)];
      }
    });
  }

  preparePayload() {
    return {
      payments: this.serialize([BalanceElement, Voucher, AccountMoney]),
      integratedPayments: this.serialize([
        TerminalPayment,
        StoredCard,
        NewStripeCard,
      ]),
    };
  }

  serialize(balance) {
    let balances = Array.isArray(balance) ? balance : [balance];
    let constructorsNames = balances.map((balance) => balance.name);
    return this.elements
      .filter(
        (method) =>
          Number(method.value) !== 0 &&
          constructorsNames.includes(method.constructor.name)
      )
      .map((m) => m.serialize())
      .flat();
  }

  reset() {
    this.elements.forEach((element) => {
      element.clearValue();
    });
    let filtered = this.elements.filter(
      (e) => e.constructor.name !== NewStripeCard
    );
    this.elements = filtered;
  }

  setLimitForAccountMoney(limit) {
    let account = this.accountMethod;
    if (account) {
      account.limit = limit;
    }
  }

  prePopulateAccountMoney(value) {
    if (typeof value === 'number') {
      let account = this.accountMethod;
      if (account && account.value === 0) {
        account.addToValue(value);
      }
    }
  }

  addNewStripeCard(c) {
    let card = new NewStripeCard(c);
    this.elements = [...this.elements, card];
    return card;
  }

  get total() {
    return [...this.elements].reduce(
      (balance, curr) => curr.processBalance(balance),
      0
    );
  }

  get storedCards() {
    return this.elements?.filter((e) => {
      return e.constructor.name === StoredCard.name;
    });
  }

  get defaultPaymentMethods() {
    return this.elements.filter((e) => e.builtIn);
  }

  get customPaymentMethods() {
    return this.elements.filter(
      (e) => !e.builtIn && e.type === PAYMENT_METHODS.OTHER
    );
  }

  get selectedMethods() {
    return this.elements.filter((e) => Number(e.value) !== 0);
  }

  get voucherMethod() {
    return this.elements.find((e) => e.type === PAYMENT_METHODS.VOUCHER);
  }

  get accountMethod() {
    return this.elements.find((e) => e.type === PAYMENT_METHODS.ACCOUNT);
  }

  get terminalMethod() {
    return this.elements.find((e) => e.type === PAYMENT_METHODS.TERMINAL);
  }

  _addDefaultElement(method) {
    let element;
    if (method.paymentType === PAYMENT_METHODS.TERMINAL) {
      element = new TerminalPayment(method);
    } else if (method.paymentType === PAYMENT_METHODS.VOUCHER) {
      element = new Voucher(method);
    } else if (method.paymentType === PAYMENT_METHODS.ACCOUNT) {
      element = new AccountMoney(method);
    } else {
      element = new BalanceElement(method.id, {
        labelKey: method.paymentType,
        type: method.paymentType,
        builtIn: true,
      });
    }
    this.elements = [...this.elements, element];
  }

  _addCustomElement(method) {
    this.elements = [
      ...this.elements,
      new BalanceElement(method.id, {
        label: method.name,
        type: method.paymentType,
        builtIn: false,
        paymentMode: method.paymentMode,
      }),
    ];
  }
}
