/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';

export default class TillSelectorModal extends Component {
  @service tillLoader;

  @tracked selectedTill;
  @tracked showWarning = false;

  resetState = modifier(
    () => {
      this.selectedTill = null;
    },
    { eager: false }
  );

  get isOpen() {
    return this.tillLoader.showTillSelectorDialog;
  }

  @action
  selectTill(till) {
    this.showWarning = false;
    this.selectedTill = till;
  }

  @action
  confirm() {
    if (!this.selectedTill) {
      this.showWarning = true;
      return;
    }
    this.tillLoader.saveCurrentTillId(this.selectedTill.id);
  }
}
