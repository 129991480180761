import { assert } from '@ember/debug';
import { getOwner } from '@ember/application';
import { variation } from 'ember-launch-darkly';

/**
 * Checks for the given access permission before running the decorated Ember
 * Concurrency task.
 * The `@permission` decorator has to be used after the Ember Concurrency task
 * decorator.
 *
 * @function
 * @param {string} [permission] Permission required to run this task
 * @param {object?} [options={}] Additional options
 * @param {function} [options.onDeny] Callback to run when access is denied
 */
export const permission = function (permission, options = {}) {
  assert(
    '@permission decorator requires a permission string as first argument',
    typeof permission === 'string'
  );

  return function accessDecorator(target, name, descriptor) {
    const original = descriptor.value;

    if (typeof original === 'function') {
      descriptor.value = function* (...args) {
        let shouldGuard = true;
        if (typeof options.guardOnlyOnVariation === 'string') {
          shouldGuard = variation(options.guardOnlyOnVariation);
        }
        const accessService = getOwner(this).lookup('service:access');
        if (shouldGuard) {
          const accessAllowed =
            yield accessService.requirePermission(permission);
          if (!accessAllowed) {
            if (typeof options.onDeny === 'function') {
              options.onDeny.call(this);
            }
            return;
          }
        }

        return yield* original.bind(this)(...args);
      };
    }

    return descriptor;
  };
};

export const guardWithPermission = function (permission) {
  return function guardWithPermissionDecorator(Route) {
    assert(
      '@guardWithPermission - you should apply it to Ember Route Class',
      Route.prototype.beforeModel
    );
    assert(
      '@guardWithPermission - you should apply it to Ember Route Class',
      Route.prototype.deactivate
    );
    return class extends Route {
      async beforeModel(transition) {
        const accessService = getOwner(this).lookup('service:access');
        const isAuthorised = await accessService.requirePermission(
          permission,
          transition
        );

        if (typeof isAuthorised === 'boolean' && isAuthorised) {
          accessService.addActivePermission(permission);
        }

        return super.beforeModel(...arguments);
      }

      deactivate() {
        const accessService = getOwner(this).lookup('service:access');
        accessService.removeActivePermission(permission);
        return super.deactivate(...arguments);
      }
    };
  };
};
