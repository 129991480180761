import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import GetVoucherBySerial from 'my-phorest/gql/queries/get-voucher-by-serial.graphql';
import { variation } from 'ember-launch-darkly';

export default class PurchaseReceiptVoucherService extends Service {
  @service session;
  @service router;
  @service('browser/window') window;
  @service electronApp;
  @service receiptSettings;

  @queryManager apollo;

  @task
  *getVouchersToPrintTask(serials) {
    let shouldPrint = yield this.shouldPrintTask.perform();

    if (shouldPrint) {
      return yield this.getVouchersBySerialsTask.perform(serials);
    }
    return [];
  }

  @task
  *printInElectronTask(serials) {
    let shouldPrint = yield this.shouldPrintTask.perform();

    if (shouldPrint) {
      for (let i = 0; i < serials.length; i++) {
        const path = this.router.urlFor(
          'accounts.account.print.voucher-receipt',
          this.session.currentAccountId,
          {
            queryParams: {
              serial: serials[i],
            },
          }
        );
        const { protocol, host } = this.window.location;
        const printURL = new URL(`${protocol}//${host}${path}`);
        this.electronApp.printReceipt(
          printURL.toString(),
          {},
          { waitForDataLoadedAttr: true }
        );
      }
    }
  }

  @task
  *shouldPrintTask() {
    if (!variation('release-receipt-voucher-print')) return false;
    let receiptSettings = yield this.receiptSettings.fetchReceiptSettings();
    if (receiptSettings?.printVoucher) {
      return true;
    }
    return false;
  }

  @task
  *getVouchersBySerialsTask(serials) {
    let promises = serials.map((serial) => {
      return this.getVoucherTask.perform(serial);
    });
    return yield Promise.all(promises);
  }

  @task
  *getVoucherTask(serial) {
    let voucher = yield this.apollo.query(
      {
        query: GetVoucherBySerial,
        variables: {
          serial,
        },
      },
      'voucherBySerial'
    );
    return voucher;
  }
}
