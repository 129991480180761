import config from 'my-phorest/config/environment';
import * as Sentry from '@sentry/ember';

export function startSentry() {
  Sentry.init({
    ...config.sentry,

    release: config.APP.deployRevision,

    denyUrls: [/https?:\/\/cdn\.prowritingaid\.com\/beyondgrammar.*/],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,

    // We tried Replay in the past and had to switch it off temporarily until some issues will be fixed:
    // see: https://github.com/phorest/my-phorest-ember/pull/2050
    // If you see any problems with the Replay module, just switch it off like in the PR above.
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      // Safe to ignore "ResizeObserver" error: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
      'ResizeObserver loop limit exceeded',

      // This error most likely comes from extensions like LastPass. We do use ResizeObserver in our code but the amount of false reports on this (from extensions)
      // makes us think to ignore this. See https://github.com/phorest/my-phorest-ember/pull/3721
      'ResizeObserver loop completed with undelivered notifications',
    ],

    integrations: [
      // We tried Replay in the past and had to switch it off temporarily until some issues will be fixed:
      // see: https://github.com/phorest/my-phorest-ember/pull/2050
      // If you see any problems with the Replay module, just switch it off like in the PR above.
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: false,
      }),
    ],

    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        let element = hint.event.target;

        let link, button;

        let pendoName = element.getAttribute('data-pendo-name');
        let name = element.getAttribute('name');
        let id = element.id;
        if (pendoName) {
          breadcrumb.message = `Clicked on "${pendoName}" link/button ([data-pendo-name])`;
        } else if (name) {
          breadcrumb.message = `Clicked on "${name}" link/button ([name])`;
        } else if (id) {
          breadcrumb.message = `Clicked on "${id}" link/button (#id)`;
        } else if ((link = linkToParent(element))) {
          let text = parentText(link);
          if (text) {
            breadcrumb.message = `Clicked on "${text}" link (inner text)`;
          }
        } else if ((button = buttonParent(element))) {
          let text = parentText(button);
          if (text) {
            breadcrumb.message = `Clicked on "${text}" button (inner text)`;
          }
        }
      } else if (breadcrumb.category === 'xhr') {
        // Don't send XHR breadcrumbs about LaunchDarkly synchronizing flags values.
        // It happens too often, is not super important and makes reading breadcrumbs harder.
        if (isLaunchDarklyEventsXhr(hint)) return null;

        let data = getXhrBreadcrumbData(hint);

        if (data) {
          breadcrumb.message = data.query;
          breadcrumb.data = data;
        }
      }

      return breadcrumb;
    },
  });
}

function buttonParent(element, depth = 3) {
  return parentWith(
    element,
    (element) => {
      let { classList } = element;
      return (
        element.tagName.toLowerCase() === 'button' ||
        element.getAttribute('role') === 'button' ||
        classList.value
          .split(/\s+/)
          .some((className) => className.includes('button'))
      );
    },
    depth
  );
}

export function getXhrBreadcrumbData(hint) {
  try {
    let input = JSON.parse(hint.input);
    let { operationName, query, variables } = input;

    if (operationName && query) {
      const ALLOWED_QUERY_TYPES = ['query', 'mutation'];
      let { status } = hint.xhr;
      let queryParts = query.split(operationName);
      let queryType = queryParts[0] && queryParts[0].trim().toLowerCase();
      let contextHeader =
        hint.xhr?.__sentry_xhr_v3__?.request_headers?.[
          'x-memento-security-context'
        ];

      if (ALLOWED_QUERY_TYPES.includes(queryType)) {
        return {
          query: `⮃ ${queryType} ${operationName} [${status}]`,
          variables: JSON.stringify(variables),
          ...(contextHeader ? { contextHeader } : {}),
        };
      }
    }
  } catch (e) {
    return null;
  }
}

export function isLaunchDarklyEventsXhr(hint) {
  if (typeof hint.xhr.responseURL === 'string') {
    return hint.xhr.responseURL.includes(
      'https://events.launchdarkly.com/events/bulk/'
    );
  }
  return false;
}

function isLinkTo(element) {
  let { tagName, classList } = element;
  return tagName.toLowerCase() === 'a' && classList.contains('ember-view');
}

function linkToParent(element, depth = 3) {
  return parentWith(element, isLinkTo, depth);
}

function parentText(element, depth = 3) {
  let parent = parentWith(element, (element) => element.innerText, depth);
  if (parent) {
    return parent.innerText.trim();
  }
}

function parentWith(element, testFn, depth) {
  if (depth === 0 || !element.parentNode) {
    return null;
  }
  if (testFn(element)) {
    return element;
  }
  return parentWith(element.parentNode, testFn, depth - 1);
}
