/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { compareSemVer } from 'my-phorest/utils/compare-semver';

export default class ElectronUpdateInfoModal extends Component {
  @service electronApp;
  @service pendo;
  @service platform;

  @tracked isOpen = false;
  @tracked osPlatform;
  @tracked osArch;
  version;

  constructor() {
    super(...arguments);
    this.checkCurrentVersion();
  }

  async checkCurrentVersion() {
    if (!this.electronApp.isRunningInElectron) return;

    if (this.platform.isWindows) {
      this.osPlatform = 'win32';
      this.osArch = 'x64';
    } else if (this.platform.isMacOS) {
      this.osPlatform = 'darwin';
      this.osArch = 'universal';
    } else {
      return;
    }

    this.version = await this.electronApp.getElectronAppVersion();

    if (compareSemVer(this.version, '10.0.0') === -1) {
      this.isOpen = true;
      this.pendo.trackEvent('Electron Update - Show update info', {
        currentVersion: this.version,
        platform: this.osPlatform,
        arch: this.osArch,
      });
      return;
    }

    if (
      this.osPlatform === 'darwin' &&
      compareSemVer(this.version, '10.6.0') === -1
    ) {
      this.isOpen = true;
      this.pendo.trackEvent('Electron Update - Show update info', {
        currentVersion: this.version,
        platform: this.osPlatform,
        arch: this.osArch,
      });
      return;
    }
  }

  @action
  onDownload() {
    this.pendo.trackEvent('Electron Update - Update downloaded', {
      currentVersion: this.version,
      platform: this.osPlatform,
      arch: this.osArch,
    });
    this.close();
  }

  @action
  dismissDialog() {
    this.pendo.trackEvent('Electron Update - User decided not to download', {
      currentVersion: this.version,
      platform: this.osPlatform,
      arch: this.osArch,
    });
    this.close();
  }

  @action
  close() {
    this.isOpen = false;
  }
}
